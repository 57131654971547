import React, { useState } from "react";
import Input from "./Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

function ContactForm() {
  const [name, setName] = useState(null);
  const [mail, setMail] = useState(null);
  const [message, setMessage] = useState(null);
  const [messageSent, setMessageSent] = useState(false);

  async function sendContactInfo(evt) {
    evt.preventDefault();
    if (name && mail && message) {
      const body = {
        name,
        email: mail,
        message,
        apiKey: "3d2b3c4d5e6f7g8h9i0j",
      };
      await axios.post("https://mailapi.savaldev.com/api/v1/contact", body);
      setMessageSent(true);
      evt.target.reset();
      setTimeout(() => {
        setMessageSent(false);
      }, 3000);
    }
  }

  return (
    <form
      onSubmit={(evt) => {
        sendContactInfo(evt);
      }}
      data-aos="flip-left"
      className="contactForm relative main-bor h-fit pb-4 w-full max-w-xl mx-auto"
    >
      {messageSent && (
        <div className="h-full w-full justify-center gap-4 flex items-center z-20 main-bg absolute inset-0">
          <span className="bold white-txt">Mensaje enviado</span>
          <FontAwesomeIcon icon={faCheckCircle} className="big-medium white-txt"/>
        </div>
      )}
      <div className="top relative">
        <span className="main-bg absolute top-0 px-8 py-4 bold white-txt">
          Contacto
        </span>
      </div>

      <div className="body justify-center mt-24 px-4">
        <div className="inputs flex flex-col gap-8 mb-4">
          <Input setState={setName} label={"Nombre"} type={"text"} required />
          <Input setState={setMail} label={"Correo"} type={"email"} required />
          <Input setState={setMessage} label={"Mensaje"} type={"text"} required />
        </div>

        <p className="w-full fs-14 light text-gray-600">
          Al suministrar su correo electrónico le contactaremos a través del
          mismo. ¡Será un gusto atenderle!
        </p>

        <button className="main-bg rounded white-txt py-4 px-6 mt-4 w-40 hover:opacity-90 transition-opacity">
          Enviar
        </button>
      </div>
    </form>
  );
}

export default ContactForm;
