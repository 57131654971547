import React from 'react';

function ServiceCard({ title, description, img }) {
  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden transition-transform hover:scale-105 h-[500px] flex flex-col">
      <div className="h-[300px] overflow-hidden">
        <img 
          src={img} 
          alt={title}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="p-6 flex-1 flex flex-col">
        <h3 className="text-xl font-bold text-primary mb-3 line-clamp-2">{title}</h3>
        <p className="text-gray-600 line-clamp-4 flex-1">{description}</p>
      </div>
    </div>
  );
}

export default ServiceCard;
