import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function Footer() {
  return (
    <>
      <footer className="main-bg ">
        <div className="colunm">
          <div className="rowSocials">
            <a href="https://whatsap.com">
              {" "}
              <FontAwesomeIcon className="main-txt" icon={faWhatsapp} />
            </a>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <a href="#inicio">
              <span className="white-txt">Inicio</span>
            </a>
            <a href="#contacto">
              {" "}
              <span className="white-txt">Visión</span>
            </a>
            <span className="white-txt">Tel: (506) 8454-0000</span>
          </div>

          <div className="colunm">
            <div className="row">
              <a href="#servicios">
                <span className="white-txt">Servicios</span>
              </a>
              <a href="#contacto">
                {" "}
                <span className="white-txt">Contacto</span>
              </a>
              <span className="white-txt">MIContadorCR@yahoo.com</span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
