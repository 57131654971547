import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faPhone,
  faMapMarker,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import TopNav from "../Components/TopNav";
import Aos from "aos";
import "aos/dist/aos.css";
import Loader from "../Components/Loader";
import Footer from "../Components/Footer";
import ContactForm from "../Components/ContactForm";
import MbNav from "../Components/MbNav";
import ServiceCard from "../Components/ServiceCard";
import servicesData from "../lib/data/services.json";

function Main() {
  const [mbNav, setMbNav] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    document.querySelector(".loaderContainer").classList.add("hidden");
  }, []);

  return (
    <>
      <Loader />
      {mbNav && <MbNav setNav={setMbNav} />}

      {/* Contact Modal */}
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative bg-white rounded-lg w-full max-w-2xl mx-4">
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              <FontAwesomeIcon icon={faTimes} className="text-xl" />
            </button>
            <div className="p-6">
              <ContactForm />
            </div>
          </div>
        </div>
      )}

      <header>
        <TopNav setNav={setMbNav} />
      </header>
      <main className="">
        <section
          id="inicio"
          className="relative min-h-screen text-white overflow-hidden"
        >
          {/* Background Image with Overlay */}
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-black/40"></div>
            <img
              src="./mainBg.jpeg"
              className="w-full h-full object-cover"
              alt="Profesionales contables en oficina"
            />
            {/* Background Pattern */}
            <div className="absolute inset-0 opacity-10">
              <div
                className="absolute inset-0"
                style={{
                  backgroundImage:
                    "url(\"data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
                }}
              ></div>
            </div>
          </div>

          {/* Content */}
          <div className="container mx-auto px-4 py-20 relative z-10 min-h-screen flex items-center">
            <div className="max-w-3xl mx-auto text-center">
              <div className="space-y-8" data-aos="fade-up">
                <div className="flex flex-col items-center gap-4">
                  <span className="text-white text-lg font-medium">
                    Tel. +506 8454-0000
                  </span>
                  <h1 className="text-4xl md:text-5xl font-bold leading-tight text-white">
                    Su asesor estratégico en Servicios Contables y Tributarios
                  </h1>
                </div>

                <div className="space-y-4">
                  <p className="text-white/90 text-xl text-shadow: 0 1px 0 rgb(0 0 0 / 40%) font-semibold">
                    Somos un equipo a la medida de sus necesidades. Queremos
                    darle el soporte Contable que su negocio necesita para el
                    crecimiento financiero, le asistimos en sus declaraciones
                    Tributarias.
                  </p>
                </div>

                <div className="flex flex-col md:flex-row justify-center gap-4">
                  <button
                    onClick={() => setShowModal(true)}
                    className="w-full md:w-[200px] bg-white text-gray-900 px-8 py-3 rounded-lg font-semibold hover:bg-gray-50 transition-colors text-center"
                  >
                    Contácteme
                  </button>
                  <a
                    href="#servicios"
                    className="w-full md:w-[200px] border-2 border-white text-white px-8 py-3 rounded-lg font-semibold hover:bg-white/10 transition-colors text-center"
                  >
                    Servicios
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="servicios" className="py-20 bg-gray-50">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16" data-aos="fade-up">
              <h2 className="text-3xl md:text-4xl font-bold text-primary mb-4">
                Nuestros Servicios
              </h2>
              <p className="text-gray-600 max-w-2xl mx-auto text-sm">
                Ofrecemos una amplia gama de servicios contables y tributarios
                para ayudarle a que su negocio crezca y se mantenga en cumplimiento con los requerimientos fiscales.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {servicesData.map((service, index) => (
                <div
                  key={index}
                  data-aos="fade-up"
                  data-aos-delay={index * 100}
                >
                  <ServiceCard
                    title={service.title}
                    description={service.description}
                    img={service.image}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>

        <section
          id="contacto"
          className="py-20 bg-white justify-center flex px-4"
        >
          <div className="flex flex-col md:flex-row items-center gap-8 md:gap-12 justify-center max-w-6xl w-full">
            <div className="w-full">
              <img
                src="./img/rodrigo.webp"
                alt="Rodrigo Contador"
                className=" max-w-[300px] md:max-w-[400px] mx-auto rounded-lg"
              />
              <h2 className="text-center font-bold mt-4">
                Lic. Rodrigo Porras Thames
              </h2>

            </div>

            <div className="flex flex-col gap-4 w-full  text-center md:text-left">
              <h2 className="text-2xl md:text-3xl font-bold text-primary">
                Mi visión
              </h2>
              <ul className="list-disc list-inside text-gray-600 text-base md:text-lg ">
                <li className="text-md">
                  Como profesional en contabilidad mi visión es asesorarle en materia
                  financiera y tributaria en la toma de decisiones informadas
                  que cumplan con sus obligaciones fiscales.
                </li>
                <li className="text-md">
                  Evaluar sus operaciones financieras para recomendarle las
                  mejores prácticas.
                </li>
                <li className="text-md">
                Identificar problemas y crear estrategias de
                  solución para que su empresa funcione de forma eficiente bajo
                  una orientación en la reducción de costos
                </li >
                <li className="text-md">
                  Realizar una evaluación de pronósticos y análisis de riesgo,
                  esto le permitirá mejorar la economía y maximizar sus
                  ingresos.
                </li>
              </ul>

              <button
                onClick={() => setShowModal(true)}
                className="bg-primary text-white px-6 py-3 rounded-lg w-fit mx-auto md:mx-0 hover:bg-primary/90 transition-colors text-base"
              >
                Contácteme
              </button>
              <div className="flex flex-col  gap-4 md:gap-4 items-center md:items-start">
                <div className="flex items-center gap-2">
                  <FontAwesomeIcon icon={faPhone} className="text-primary" />
                  <p className="text-gray-600 text-sm">+506 8454-0000</p>
                </div>

                <div className="flex items-center gap-2">
                  <FontAwesomeIcon icon={faAt} className="text-primary" />
                  <p className="text-gray-600 text-sm">
                    MIContadorCR@yahoo.com
                  </p>
                </div>

                <div className="flex items-center gap-2">
                  <FontAwesomeIcon
                    icon={faMapMarker}
                    className="text-primary"
                  />
                  <p className="text-gray-600 text-sm">San José, Costa Rica</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
}

export default Main;
