import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

function TopNav({setNav}) {
  return (
    <nav className="fixed top-0 w-full z-50 backdrop-blur-sm">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-20">
          {/* Mobile Menu Button */}
          <button 
            className="lg:hidden text-white hover:text-teal-100 transition-colors"
            onClick={() => setNav(true)}
          >
            <FontAwesomeIcon className="text-2xl" icon={faBars} />
          </button>

          {/* Logo */}
          <div className="items-center gap-2 hidden md:flex mb-hide">
            <span className="text-white font-semibold text-lg">Mi Contador CR</span>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden lg:flex items-center gap-8 mb-hide">
            <a 
              href="#inicio" 
              className="text-white hover:text-teal-100 transition-colors font-bold"
            >
              Inicio
            </a>
            <a 
              href="#contacto" 
              className="text-white hover:text-teal-100 transition-colors font-bold"
            >
              Visión
            </a>
            <a 
              href="#servicios" 
              className="text-white hover:text-teal-100 transition-colors font-bold"
            >
              Servicios
            </a>
            <a 
              href="#contacto" 
              className="text-white hover:text-teal-100 transition-colors font-bold"
            >
              Contacto
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default TopNav;
